import React, { Component } from "react";
import { Row, Col, Table } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import WinnersApi from "../api/winners.js";

export default class Winners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      winners: null,
      count: 0,
    };
  }

  async getWinnersForAdmin() {
    try {
      var attempts = await WinnersApi.getAll();
      this.setState({ winners: attempts });
    } catch (error) {
      toast.error("Грешка при преземањето на добитниците");
      throw error;
    }
  }
  async componentDidMount() {
    await this.getWinnersForAdmin();
  }

  renderPlatform(statusId) {
    switch (statusId) {
      case 1:
        return "СМС";
      case 2:
        return "ВЕБ";
      case 3:
        return "ВИБЕР";
    }
  }

  render() {
    return (
      <Row className="table-section">
        <Col lg={12}>
          <figure className="icon-group">
            <h2 className="heading ml-3">Добитници</h2>
          </figure>

          <section className="list-box mt-3">
            <Table responsive="xl" className="mb-5">
              <thead className="table-head">
                <tr>
                  <th>Код</th>
                  <th>Телефон</th>
                  <th>Име</th>
                  <th>Град</th>
                  <th>Платформа</th>
                  <th>Испратен на</th>
                  <th>Извлечен на</th>
                </tr>
              </thead>
              <tbody>
                {this.state.winners
                  ? this.state.winners.map((i) => {
                      let date = new Date(i.sent).toLocaleString("en-GB");
                      const updated = new Date(i.updated).toLocaleDateString(
                        "en-GB"
                      );
                      return (
                        <tr key={i._id}>
                          <td>{i.code_number}</td>
                          <td>{i.phone_number}</td>
                          <td>{i.full_name}</td>
                          <td>{i.city}</td>
                          <td>{this.renderPlatform(i.platform)}</td>
                          <td>{date}</td>
                          <td>{updated}</td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>
          </section>
        </Col>
      </Row>
    );
  }
}
